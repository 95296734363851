<template>
	<div class="cog-branding" :class="['cog-branding--' + (minimalBranding ? 'minimal' : 'maximal')]">
		<link v-if="needsFont" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=Lato:700&text=' + encodeURIComponent($resource('powered-by-cognito')) + encodeURIComponent($resource('marketing-button-1'))">
		<a v-if="CRSReferral" :href="CRSReferral" target="_blank">
			<span class="cog-branding__powered">
				<i-cogicon />
				{{ $resource("powered-by-cognito") }}
			</span>
			<template v-if="!minimalBranding">
				<span class="cog-branding__button">
					{{ $resource('marketing-button-1') }}
				</span>
			</template>
		</a>
	</div>
</template>

<script>
	import ICogicon from 'src/assets/graphics/cogicon.svg';

	export default {
		name: 'CBranding',
		components: { ICogicon },
		inject: ['$resource', 'form'],
		props: {
			minimalBranding: { type: Boolean, default: false },
			CRSReferral: { type: String, default: null },
			needsFont: { type: Boolean, default: true }
		},
		mounted() {
			if (this.$props.minimalBranding)
				return false;

			this.form.$el.addEventListener('keyup', (e) => {
				if (e.which !== 9)
					return false;

				const brandingRect = this.$el.getBoundingClientRect();
				const focusedRect = e.target.getBoundingClientRect();

				if (focusedRect.bottom > brandingRect.top)
					this.form.$el.scrollBy(0, brandingRect.height);
			});
		}
	};
</script>

<style lang="scss">
@import 'src/style/brand-colors';

#{$specificity-base} {

	.cog-branding {
		font-family: Lato, sans-serif;
		font-size: 16px !important;
		font-weight: bold;

		a {
			text-decoration: none;
		}
	}

	.cog-branding__powered {
		color: $matins;
		font-size: .875em !important;
	}

	.cog-i-cogicon {
		display: inline-block !important;
		width: 1.5em;
		height: 1.5em;
		margin-top: -.15em;
		margin-right: .2em;
		vertical-align: middle;
		font-size: inherit !important;
	}

	.cog-i-cogicon__cog {
		fill: $jaywalk !important;
	}

	.cog-i-cogicon__c {
		fill: white !important;
	}

	.cog-branding--maximal {
		@media screen {
			position: fixed;
			background: $brady;
			box-shadow: 0 0 2px rgba($ansel, .4);
		}
		display: flex;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: $maximal-branding--z;
		align-items: center;
		justify-content: center;
		padding: 16px 8px;
	}

	.cog-branding__button {
		display: inline-block;
		background: $ansel;
		margin-left: 1.3em;
		padding: .6em .9em;
		color: white;
		font-size: .875em;
		transition: background $speed-quarter;

		@media print {
			margin: 0;
			padding: 0;
			color: $matins;

			&::after {
				content: ' - cognitoforms.com';
			}
		}
	}

	.cog-branding__button:hover,
	a:focus .cog-branding__button {
		background: $matins;
	}

	a:focus .cog-branding__button {
		// box-shadow: inset 0 0 0 1px rgba($brady, .6);
		outline: 3px solid white;
	}

	.cog-branding--minimal {
		padding-top: 24px;
		text-align: center;

		.cog-branding__powered {
			display: inline-block !important;
			width: auto !important;
			height: auto !important;
			background: hsla(0deg, 0%, 100%, 70%);
			padding: 12px 16px;
			color: $ansel !important;
			font-size: inherit;
			transition: all var(--speed);
		}

		& .cog-branding__powered:hover {
			background: white;
			color: $matins;
		}
	}

	.cog-form--dark-background {

		.cog-branding--minimal {

			.cog-branding__powered {
				background: $matins;
				border: 3px solid $midnight;
				color: $puff !important;

				&:hover {
					background: $midnight;
					color: white;
				}
			}
		}
	}

	@media (max-width: 410px) {

		[lang='en'] .cog-branding {
			font-size: 4vw !important;
		}
	}

	// Make branding text smaller earlier for non-English languages which can be longer.
	// German: Erstellt mit Cognito Forms. - Probieren Sie Es Jetzt
	// and
	// Turkish: Cognito Forms tarafından yapılmıştır. - Şimdi Dene
	// seem to be the longest (as always).
	@media (max-width: 450px) {

		.cog-form:not([lang='en']) {

			.cog-branding {
				font-size: 3.75vw !important;
			}

			.cog-i-cogicon {
				width: 1.7em;
				height: 1.7em;
			}

			.cog-branding__button {
				margin-left: 2vw;
				padding: 1.5vw;
			}
		}
	}

	// Just to prevent people messing with our branding
	.cog-branding--minimal,
	.cog-branding--minimal * {
		position: static !important;
		overflow: visible !important;
		opacity: 1 !important;
		visibility: visible !important;
		transform: scale(1) translateX(0) translateY(0) !important;
	}
}
</style>