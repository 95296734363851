var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "cog-branding",
      class: ["cog-branding--" + (_vm.minimalBranding ? "minimal" : "maximal")]
    },
    [
      _vm.needsFont
        ? _c("link", {
            attrs: {
              rel: "stylesheet",
              href:
                "https://fonts.googleapis.com/css?family=Lato:700&text=" +
                encodeURIComponent(_vm.$resource("powered-by-cognito")) +
                encodeURIComponent(_vm.$resource("marketing-button-1"))
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.CRSReferral
        ? _c(
            "a",
            { attrs: { href: _vm.CRSReferral, target: "_blank" } },
            [
              _c(
                "span",
                { staticClass: "cog-branding__powered" },
                [
                  _c("i-cogicon"),
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.$resource("powered-by-cognito")) +
                      "\n\t\t"
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.minimalBranding
                ? [
                    _c("span", { staticClass: "cog-branding__button" }, [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.$resource("marketing-button-1")) +
                          "\n\t\t\t"
                      )
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }